import React from 'react'

import ArrowLeftSVG from '../../../assets/images/arrow-left.svg'

import * as S from './styled'

const ArrowLeft = ({ differentials }) => (
  <S.ButtonLeftContainer differentials={differentials}>
    <S.Arrow differentials={differentials}>
      <use xlinkHref={`#${ArrowLeftSVG.id}`} />
    </S.Arrow>
  </S.ButtonLeftContainer>
)

export default ArrowLeft
