import styled from 'styled-components'
import media from 'styled-media-query'

export const ButtonContainer = styled.div`
  position: absolute;
  background-color: ${props => props.theme.colors.white};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 50%;
  width: 3.2rem;
  height: 3.2rem;
  bottom: ${props => (props.differentials ? '42%' : '50%')};
  z-index: 50;
  opacity: 0.8;
  cursor: pointer;

  &:hover {
    opacity: 1;
    transform: scale(1.04) !important;
  }

  ${media.lessThan('medium')`
    opacity: 1;
  `}
`

export const ButtonLeftContainer = styled(ButtonContainer)`
  left: 4rem;

  ${media.lessThan('medium')`
    left: ${props => (props.differentials ? '-1.5rem' : '1rem')};
  `}
`

export const ButtonRightContainer = styled(ButtonContainer)`
  right: 4rem;

  ${media.lessThan('medium')`
    right: ${props => (props.differentials ? '-1.5rem' : '1rem')};
  `}
`

export const Arrow = styled.svg`
  width: 1.6rem;
  height: 1.6rem;
  display: block;
  position: relative;
  top: 0.8rem;
  left: 0.8rem;
  color: ${props => props.theme.colors.white};
  cursor: pointer;
  z-index: 50;
`
