import React, { useState, createContext } from 'react'

const GeographicalStateContext = createContext()

export const GeographicalStateProvider = props => {
  const [geographicalState, _setGeographicalState] = useState('São Paulo')
  const setGeographicalState = state => _setGeographicalState(state)
  const getGeographicalState = () => geographicalState
  return (
    <GeographicalStateContext.Provider
      value={{
        setGeographicalState,
        getGeographicalState,
      }}
      {...props}
    />
  )
}

export const useGeographicalState = () => {
  const context = React.useContext(GeographicalStateContext)
  if (context === undefined) {
    throw new Error('useGeographicalState must be used within a GeographicalStateProvider')
  }
  return context
}
