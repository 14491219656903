import React from 'react'

import ArrowRightSVG from '../../../assets/images/arrow-right.svg'

import * as S from './styled'

const ArrowRight = ({ differentials }) => (
  <S.ButtonRightContainer differentials={differentials}>
    <S.Arrow differentials={differentials}>
      <use xlinkHref={`#${ArrowRightSVG.id}`} />
    </S.Arrow>
  </S.ButtonRightContainer>
)

export default ArrowRight
